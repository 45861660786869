<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="570px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog">
    <div>
      <div class="form-item flex flex-center">
        <span class="form-label">
          <span class="must">*</span>
          角色名称
        </span>
        <el-input class="flex-1" v-model="form.roleName" size="small" maxlength="8" show-word-limit></el-input>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label self-start">
          角色说明
        </span>
        <el-input type="textarea" class="flex-1" v-model="form.description" size="small" maxlength="255" rows="5" show-word-limit></el-input>
      </div>
    </div>
    <div slot="footer">
      <el-button size="small" @click="hideDialog">取消</el-button>
      <el-button type="primary" size="small" @click="confirm" :disabled="loading">
        保存
        <i class="el-icon-loading" v-show="loading"></i>
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add_role, update_role } from '@/api/user'
import { FormValid } from "@/utils/formValid";

const form = {
  roleName: '',
  description: ''
}
export default {
  props: {
    visible: {
      type: Boolean
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      form: this.$deepClone(form),
      loading: false
    }
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.info.id) {
          this.form = this.$deepClone(this.info)
        }
      } else {
        this.form = this.$deepClone(form)
      }
    }
  },
  computed: {
    title() {
      return this.info.id ? '编辑角色' : '新增角色'
    },
    checkFormList() {
      let arr = [
        {
          label: '角色名称',
          value: 'roleName',
          methods: ['notEmpty']
        }
      ]
      return arr
    }
  },
  methods: {
    hideDialog() {
      this.$emit('close')
    },
    checkForm() {
      const formValid = new FormValid();
      for (const f of this.checkFormList) {
        const { label, value, methods } = f;
        const v = this.form[value]
        formValid.add(label, v, methods)
      }
      const res = formValid.start();
      if (res !== true) {
        this.$message.warning(res)
        return false;
      }
      return {
        ...this.form
      };
    },
    confirm() {
      let params = this.checkForm()
      if (!params) return
      this.loading = true
      if (this.info.id) {
        update_role(params).then(() => {
          this.loading = false
          this.$message.success('保存成功')
          this.$emit('confirm')
        }).catch(() => {
          this.loading = false
        })
      } else {
        add_role(params).then(() => {
          this.loading = false
          this.$message.success('保存成功')
          this.$emit('confirm')
        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-item{
  padding-left: 7px;
  margin-bottom: 16px;
  .must{
    color: #E14B38
  }
  .form-label{
    width: 80px;
    text-align: right;
    padding-right: 20px;
  }
}
</style>