import { ajax } from "@/api/request";

// 鼎壹配置权限列表
export const get_dy_permission = function(params) {
  return ajax({
    method: 'get',
    url: '/permission/v1/getDyPermissionConfigList',
    params
  });
}

// 鼎壹权限查询
export const get_dy_permission_detail = function(params) {
  return ajax({
    method: 'get',
    url: '/permission/v1/queryDyPermissionConfig',
    params
  });
}

// 鼎壹权限保存
export const save_dy_permission = function(data) {
  return ajax({
    method: 'post',
    url: '/permission/v1/saveDyPermissionConfig',
    data
  });
}

// 鼎壹权限更新
export const update_dy_permission = function(data) {
  return ajax({
    method: 'post',
    url: '/permission/v1/updateDyPermissionConfig',
    data
  });
}

// 鼎壹权限 - 上级菜单
export const get_dy_upper_menu = function(params) {
  return ajax({
    method: 'get',
    url: '/permission/v1/dyUpperMenuList',
    params
  });
}

// 鼎壹权限角色列表
export const get_dy_role = function(params) {
  return ajax({
    method: 'get',
    url: '/permission/v1/dyPermissionRoleList',
    params
  });
}

// 鼎壹角色授权
export const bind_dy_permission_role = function(data) {
  return ajax({
    method: 'post',
    url: '/permission/v1/dyPermissionRoleBind',
    data
  });
}

// 鼎壹角色列表
export const get_role_list = function(data) {
  return ajax({
    method: 'post',
    url: '/permission/v1/dyRoleList',
    data
  });
}

// 鼎壹角色复制
export const copy_role = function(data) {
  return ajax({
    method: 'post',
    url: '/permission/v1/copyDyRole',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

// 鼎壹角色删除
export const delete_role = function(data) {
  return ajax({
    method: 'post',
    url: '/permission/v1/deleteDyRole',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

// 鼎壹角色新增
export const add_role = function(data) {
  return ajax({
    method: 'post',
    url: '/permission/v1/addDyRole',
    data
  });
}

// 鼎壹角色更新
export const update_role = function(data) {
  return ajax({
    method: 'post',
    url: '/permission/v1/updateDyRole',
    data
  });
}

// 鼎壹角色权限列表
export const get_dy_role_permission = function(params) {
  return ajax({
    method: 'get',
    url: '/permission/v1/getDyRolePermissionConfigList',
    params
  });
}

// 鼎壹角色权限绑定
export const bind_dy_role_permission = function(data) {
  return ajax({
    method: 'post',
    url: '/permission/v1/dyRolePermissionBind',
    data
  });
}

// 鼎壹用户列表
export const get_user_list = function(data) {
  return ajax({
    method: 'post',
    url: '/permission/v1/dyUserList',
    data
  });
}

// 鼎壹用户新增
export const add_user = function(data) {
  return ajax({
    method: 'post',
    url: '/permission/v1/saveDyUser',
    data
  });
}

// 鼎壹用户更新
export const update_user = function(data) {
  return ajax({
    method: 'post',
    url: '/permission/v1/updateDyUser',
    data
  });
}

// 鼎壹用户禁用
export const disable_user = function(data) {
  return ajax({
    method: 'post',
    url: '/permission/v1/disableDyUser',
    params: data
  });
}

// 鼎壹用户启用
export const enable_user = function(data) {
  return ajax({
    method: 'post',
    url: '/permission/v1/enableDyUser',
    params: data
  });
}

// 重置密码
export const update_pwd = function(data) {
  return ajax({
    method: 'post',
    url: '/user/v1/updatePwd',
    params: data
  });
}

//修改用户基本信息
export const post_updateUserInfo = function(data) {
  return ajax({
    method: 'post',
    url: '/user/v1/updateUserInfo',
    data: data
  });
}

//旧密码修改密码
export const post_updatePwdByOldPwd = function(data) {
  return ajax({
    method: 'post',
    url: '/user/v1/updatePwdByOldPwd',
    params: data
  });
}

