<template>
  <el-dialog
    title="授权"
    :visible.sync="visible"
    width="570px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    custom-class="scroll-dialog"
    :before-close="hideDialog">
    <div>
      <el-tree
        :data="list"
        ref="authTree"
        show-checkbox
        node-key="id"
        default-expand-all
        :props="defaultProps"
        :check-strictly="true"
        :default-checked-keys="defaultChecked"
        @check="handleCurrentChange"
      >
      </el-tree>
    </div>
    <div slot="footer">
      <el-button size="small" @click="hideDialog">取消</el-button>
      <el-button type="primary" size="small" @click="confirm" :disabled="loading">
        保存
        <i class="el-icon-loading" v-show="loading"></i>
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { get_dy_role_permission, bind_dy_role_permission } from '@/api/user'

export default {
  props: {
    visible: {
      type: Boolean
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      list: [],
      defaultProps: {
        children: 'child',
        label: 'note'
      },
      defaultChecked: [],
      loading: false
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.getRoleTree()
      } else {
        this.list = []
        this.defaultChecked = []
      }
    }
  },
  computed: {
  },
  methods: {
    async getRoleTree() {
      this.list = await get_dy_role_permission({
        id: this.info.id
      })
      this.list.map(o => {
        o.checked && this.defaultChecked.push(o.id)
        o.child && o.child.map(i => {
          i.checked && this.defaultChecked.push(i.id)
          i.child && i.child.map(v => {
            v.checked && this.defaultChecked.push(v.id)
          })
        })
      })
    },
    hideDialog() {
      this.$emit('close')
    },
    handleCurrentChange(item, node) {
      console.log(item, node)
      const thisnode = this.$refs.authTree.getNode(item.id)
      const checked = thisnode.checked
      if (checked) {
        item.child && item.child.map(o => {
          this.$refs.authTree.setChecked(o.id, true);
          o.child && o.child.map(i => {
            this.$refs.authTree.setChecked(i.id, true);
          })
        })
        if (item.parentId != -1) {
          this.$refs.authTree.setChecked(item.parentId, true);
        }
      } else {
        item.child && item.child.map(o => {
          this.$refs.authTree.setChecked(o.id, false);
          o.child && o.child.map(i => {
            this.$refs.authTree.setChecked(i.id, false);
          })
        })
      }
    },
    confirm() {
      const permissionIdList = this.$refs.authTree.getCheckedKeys()
      console.log(permissionIdList)

      let params = {
        roleId: this.info.id,
        permissionIdList
      }
      this.loading = true
      bind_dy_role_permission(params).then(() => {
        this.loading = false
        this.$message.success('授权成功')
        this.hideDialog()
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>