<template>
  <div>
    <div class="page-content">
      <div class="page-tips-title">
        <i class="el-icon-warning-outline"></i> 提示：
      </div>
      <div class="page-tips-info">
        1.运维平台角色拥有操作权限，操作权限指具有该角色的用户能否操作某页面或数据；2.超级管理员为系统角色，默认拥有所有权限，不能修改。
      </div>
    </div>
    <div class="page-content m-t-16">
      <div class="page-sub-title flex flex-center">
        <span class="flex-1">筛选查询</span>
      </div>
      <div>
        <div class="search-item">
          <span class="label">角色名称</span>
          <el-input v-model="searchParams.search" size="small" style="width: 240px" clearable placeholder="请输入角色名称"></el-input>
        </div>
        <div class="search-item">
          <el-button type="primary" size="small" @click="search">查询</el-button>
          <el-button class="border-btn" plain size="small" @click="reset">重置</el-button>
        </div>
      </div>
      <div class="page-sub-title flex flex-center">
        <span class="flex-1">角色管理</span>
        <el-button type="primary" size="small" @click="showEditDialog()">+ 新增角色</el-button>
      </div>
      <el-table
        :data="list"
        style="width: 100%"
        border
        header-row-class-name="table-header"
      >
        <el-table-column
          align="center"
          prop="roleCode"
          label="角色编号"
          width="160"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="roleName"
          label="角色名称"
          min-width="160"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="description"
          label="角色说明"
          min-width="250"
        ></el-table-column>
        <el-table-column
          align="center"
          label="操作"
          width="200"
        >
          <template slot-scope="scope">
            <el-button type="text" :disabled="scope.row.roleCode == adminCode" @click="copyRole(scope.row)">复制</el-button>
            <el-button type="text" :disabled="scope.row.roleCode == adminCode" @click="showEditDialog(scope.row)">编辑</el-button>
            <el-button type="text" :disabled="scope.row.roleCode == adminCode" @click="deleteRole(scope.row)">删除</el-button>
            <el-button type="text" :disabled="scope.row.roleCode == adminCode" @click="showAuthDialog(scope.row)">授权</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        class="text-right m-t-16"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="pageSizes"
        :current-page="pageParams.pageNo"
        :page-size="pageParams.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems">
      </el-pagination>
    </div>

    <edit-role-dialog :visible="editVisible" :info="roleData" @close="hideEditDialog" @confirm="confirmEditDialog"></edit-role-dialog>

    <auth-tree-dialog :visible="authVisible" :info="roleData" @close="hideAuthDialog"></auth-tree-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { pageMixin } from '@/mixins/pageMixin'
import { get_role_list, copy_role, delete_role } from '@/api/user'
import editRoleDialog from './component/edit-role-dialog'
import authTreeDialog from './component/auth-tree-dialog'

export default {
  mixins: [pageMixin],
  components: {
    editRoleDialog,
    authTreeDialog
  },
  data() {
    return {
      searchParams: {
        search: ''
      },
      editVisible: false,
      roleData: {},
      authVisible: false
    }
  },
  computed: {
    ...mapState(['adminCode'])
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      let params = {
        ...this.pageParams,
        ...this.searchParams
      }
      const res = await get_role_list(params)
      console.log(res)
      this.list = res ? res.records : []
      this.totalItems = res ? res.total : 0
    },
    showEditDialog(row) {
      if (row) {
        this.roleData = this.$deepClone(row)
      } else {
        this.roleData = {}
      }
      this.editVisible = true
    },
    hideEditDialog() {
      this.roleData = {}
      this.editVisible = false
    },
    confirmEditDialog() {
      this.hideEditDialog()
      this.getList()
    },
    showAuthDialog(row) {
      this.authVisible = true
      this.roleData = this.$deepClone(row)
    },
    hideAuthDialog() {
      this.roleData = {}
      this.authVisible = false
    },
    copyRole(row) {
      this.$confirm(`确定要复制 ${row.roleName} 吗？`, '复制角色', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'dialog-class',
        center: true
      }).then(() => {
        let params = new FormData()
        params.append('id', row.id)
        copy_role(params).then(() => {
          this.$message.success('复制成功')
          this.reset()
        })
      })
    },
    deleteRole(row) {
      this.$confirm('此操作将永久删除，是否继续？', '删除角色', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'dialog-class',
        center: true
      }).then(() => {
        let params = new FormData()
        params.append('id', row.id)
        delete_role(params).then(() => {
          this.$message.success('删除成功')
          this.reset()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-tips-title{
  font-size: 16px;
  color: #8B929E;
  margin-bottom: 10px;
}
.page-tips-info{
  color: #8B929E;
  text-indent: 1em;
}
</style>