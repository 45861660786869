import { render, staticRenderFns } from "./auth-tree-dialog.vue?vue&type=template&id=ddd40c10&scoped=true"
import script from "./auth-tree-dialog.vue?vue&type=script&lang=js"
export * from "./auth-tree-dialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddd40c10",
  null
  
)

export default component.exports